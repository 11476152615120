import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  InputBase,
  Drawer,
  Grid,
  Avatar,
  MenuItem,
  Box,
  Container,
  List,
  Typography,
  fade,
  Menu,
  Grow,
  Paper,
  Popper,
  MenuList,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "./../../component/Logo";
import Dialog from "@material-ui/core/Dialog";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ListItem } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useWeb3React } from "@web3-react/core";
import { sortAddress } from "src/utils";
import { UserContext } from "src/context/User";
import { ACTIVE_NETWORK } from "src/constants";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { toast } from "react-toastify";
import { Tooltip } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FiCopy } from "react-icons/fi";
import PersonIcon from "@material-ui/icons/Person";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";
import NotificationsIcon from "@material-ui/icons/Notifications";
import axios from "axios";
import Apiconfigs from "../../../src/config/ApiConfig";
import { GiCancel } from "react-icons/gi";
import Badge from "@material-ui/core/Badge";

const headersData = [
  {
    label: "Explore",
    href: "/search-item",
    auth: false,
  },
  {
    label: "My items",
    href: "/my-items",
    auth: true,
  },
  {
    label: "Following",
    href: "/following",
    auth: true,
  },
  {
    label: "Activity",
    href: "/activity",
    auth: true,
  },
  {
    label: "How it works",
    href: "/faq",
  },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "13px",
    lineHeight: "24px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    color: "#757575",
    height: "31px",
    padding: "0px 5px",
    letterSpacing: "2px",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#000000",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&:active": {
      color: "#1ed760",
    },
    "&:last-child": {
      backgroundColor: "#1ed760",
    },
  },
  menuButton1: {
    width: "100%",
    display: "block",
  },
  login: {
    backgroundColor: "#0D8CCD",
    marginLeft: "10px",
  },
  loginButton: {
    height: "28px",

    width: "28px",
  },
  toolbar: {
    display: "flex",
    padding: "10px 0",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "16px ",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    borderBottom: "1px solid #3e3e3e",
    padding: "10px 0",
    maeginLeft: "-20px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
      color: "black",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #0D8CCD",
    },
    marginLeft: 10,
    marginRight: 10,
    width: "140px",
    maxWidth: "262px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "265px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 1),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "12px",
    width: "100%",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#0D8CCD",
    border: "1px solid #0D8CCD",
    padding: "0 15px",
    borderRadius: "50px",
    height: "31px",
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "11px",
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #0D8CCD",
    top: "25px !important",
    "& .MuiMenuItem-root": {
      fontSize: "13px",
      fontWeight: "700",
    },
  },
  notificationIcon: {
    background: "red",
    marginLeft: "-13px",
    marginTop: "-10px",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    fontSize: "10px",
    color: "#ca4a4a",
  },
  paper: {
    overflowY: "unset",
  },
  customizedButton: {
    position: "absolute",
    top: "-42px",
    right: "-9px",
    color: "#fff",
  },
  dialogBox: {
    padding: "20px",
  },
}));

function myFunction() {
  var copyText = document.getElementById("myInput");
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */
  navigator.clipboard.writeText(copyText.value);
  toast.info(`Copied ${copyText.value}`);
}

export default function Header() {
  const { account, chainId } = useWeb3React();
  const [isWrongNetwork, setIsWrongNetwork] = useState(false);

  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {
    loginButton,
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    login,
    logoDrawer,
    community,
    containerHeight,
    search,
    searchIcon,
    mainHeader,
    inputInput,
    inputRoot,
    wallet,
    submenu,
    notificationIcon,
    customizedButton,
    paper,
    dialogBox,
  } = useStyles();
  const history = useHistory();

  const location = useLocation();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const [searchText, setSearchText] = useState("");
  const searchTextRef = React.useRef(null);

  useEffect(() => {
    searchTextRef.current.focus();
    if (
      location.pathname === "/item" &&
      location.search &&
      location.search.slice(1, location.search.length)
    ) {
      let text = location.search.slice(1, location.search.length);
      setSearchText(text);
    }
  }, [location]);

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };

  const handleToggle = (name) => {
    setOpen1({ ...open1, [name]: !open1[name] });
  };

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open1);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open1 === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open1;
  // }, [open1]);

  const readNotificationhandler = async () => {
    try {
      const res = await axios.get(Apiconfigs.readNotification, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      });
    } catch (error) {}
  };

  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "5px" }}
          >
            <div className={search}>
              <div className={searchIcon}>
                <SearchIcon style={{ fontSize: "15px" }} />
              </div>
              <InputBase
                ref={searchTextRef}
                autoFocus
                placeholder="Search  Items, Users and Collections"
                value={searchText}
                autoFocus={true}
                onChange={(e) => {
                  setSearchText(e.target.value);

                  history.push({
                    pathname: "/item",
                    search: e.target.value,
                  });
                }}
                classes={{
                  root: inputRoot,
                  input: inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
            {getMenuButtons()}
            <Button
              ref={anchorRef.community}
              aria-controls={open1.community ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={() => handleToggle("community")}
              endIcon={<ExpandMoreIcon />}
              className={menuButton}
            >
              Community
            </Button>
            {/* <NotificationsNoneIcon
              style={{
                borderRadius: "50%",
                border: "1px solid gray",
                margin: "7px",
                color: "#757575",
                cursor: "pointer",
                fontSize: "20px",
              }}
            /> */}
            <Popper
              open={open1.community}
              anchorEl={anchorRef.community.current}
              role={undefined}
              transition
              disablePortal
              className={submenu}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={(e) => handleClose2(e, "community")}
                    >
                      <MenuList
                        autoFocusItem={open1}
                        id="menu-list-grow"
                        onKeyDown={(e) => handleListKeyDown(e, "community")}
                      >
                        <MenuItem component={Link} to="/fungy-token">
                          Fungy Token
                        </MenuItem>
                        <MenuItem component={Link} to="/faq">
                          Discussion
                        </MenuItem>
                        <MenuItem>Voting</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            {/* <Button
              className={menuButton}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              endIcon={<ExpandMoreIcon />}
            >
              Community
            </Button> */}
            {account && user.isLogin && (
              <input
                id={"myInput"}
                value={account && account}
                style={{ display: "none" }}
              />
            )}

            <Box>
              <IconButton
                onClick={() => {
                  readNotificationhandler();
                  history.push("/notification");
                }}
              >
                <Badge
                  color="primary"
                  badgeContent={0}
                  showZero
                  style={{ transform: "scale(.7)" }}
                >
                  <NotificationsIcon />
                  {user?.unreadCount > 0 && (
                    <Box className={notificationIcon}>0</Box>
                  )}
                </Badge>
              </IconButton>
            </Box>

            {!user.isLogin && (
              <Button
                className={wallet}
                aria-controls="simple-menu"
                aria-haspopup="true"
                to="/wallet"
                component={Link}
              >
                Connect wallet
              </Button>
            )}
            {account && user.isLogin && (
              <IconButton
                aria-label="delete"
                size="small"
                className={login}
                ref={anchorRef.user}
                aria-controls={open1.user ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={() => handleToggle("user")}
              >
                {" "}
                <Avatar
                  className={loginButton}
                  src={
                    user.userData && user.userData.profilePic
                      ? user.userData.profilePic
                      : "images/user1.png"
                  }
                />
              </IconButton>
            )}
            <Popper
              open={open1.user}
              anchorEl={anchorRef.user.current}
              role={undefined}
              transition
              disablePortal
              className={submenu}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper style={{ fontWeight: "700" }}>
                    <ClickAwayListener
                      onClickAway={(e) => handleClose2(e, "user")}
                    >
                      <MenuList
                        autoFocusItem={open1}
                        id="menu-list-grow"
                        onKeyDown={(e) => handleListKeyDown(e, "user")}
                      >
                        <MenuItem component={Link} to="/create">
                          <AddCircleOutlineIcon />
                          <span
                            style={{ margin: "2px 0 0 10px", fontSize: "16px" }}
                          >
                            {" "}
                            Create Collectible
                          </span>
                        </MenuItem>
                        <MenuItem
                          component={Link}
                          to={{
                            pathname: "/user",
                            search: user.userData?._id,
                          }}
                        >
                          <PersonIcon />{" "}
                          <span
                            style={{ margin: "2px 0 0 10px", fontSize: "16px" }}
                          >
                            {" "}
                            My Profile
                          </span>
                        </MenuItem>
                        <MenuItem
                          component={Button}
                          onClick={() => {
                            setOpen1({ ...open1, user: false });
                            user.logoutHandler();
                          }}
                        >
                          <LockTwoToneIcon />
                          <span
                            style={{ margin: "2px 0 0 10px", fontSize: "16px" }}
                          >
                            {" "}
                            Logout
                          </span>
                        </MenuItem>
                        <MenuItem component={Button}>
                          <Tooltip title="Address" placement="top">
                            <Typography
                              variant="h5"
                              style={{
                                color: "#0c8ecf",
                                fontSize: "15px",
                                fontWeight: "500",
                              }}
                            >
                              {sortAddress(account)}
                              <CopyToClipboard text={account}>
                                <FiCopy onClick={() => myFunction()} />
                              </CopyToClipboard>{" "}
                            </Typography>
                          </Tooltip>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            {/* <Button variant="contained" size="small" color="secondary" onClick={handleClickOpen}>
        Connect
      </Button> */}
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.png" alt="" />
            <IconButton aria-label="delete" size="small" className={login}>
              {" "}
              <Avatar className={loginButton} src={"images/user1.png"} />
            </IconButton>
            {getDrawerChoices()}
            <Button
              // className={menuButton}
              className={menuButton}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              endIcon={<ExpandMoreIcon />}
            >
              Community
            </Button>
            <Box>
              <IconButton
                onClick={() => {
                  readNotificationhandler();
                  history.push("/notification");
                }}
              >
                <NotificationsIcon />
                {user?.unreadCount > 0 && (
                  <Box className={notificationIcon}>0</Box>
                )}
                <span>Notification</span>
              </IconButton>
            </Box>

            {account && user.isLogin ? (
              <Button
                className={wallet}
                aria-controls="simple-menu"
                aria-haspopup="true"
              >
                {sortAddress(account)}{" "}
              </Button>
            ) : (
              <Button
                className={wallet}
                aria-controls="simple-menu"
                aria-haspopup="true"
                to="/wallet"
                component={Link}
              >
                Connect wallet
              </Button>
            )}
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}>
            <div className={search}>
              <div className={searchIcon}>
                <SearchIcon style={{ fontSize: "12px" }} />
              </div>
              <InputBase
                ref={searchTextRef}
                value={searchText}
                autoFocus={true}
                onChange={(e) => {
                  setSearchText(e.target.value);

                  history.push({
                    pathname: "/item",
                    search: e.target.value,
                  });
                }}
                placeholder="Search…"
                classes={{
                  root: inputRoot,
                  input: inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#197ab3", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      );
    });
  };

  useEffect(() => {
    if (chainId && ACTIVE_NETWORK && chainId !== ACTIVE_NETWORK) {
      console.log("--");
      setIsWrongNetwork(true);
    } else {
      setIsWrongNetwork(false);
    }
  }, [chainId, ACTIVE_NETWORK]);

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        // className={history.location.pathname !== "/" ? "InnerHeader" : "Header"}
        style={{ backgroundColor: "#f4f4f4", position: "fixed" }}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        {user.userData && (
          <MenuItem>
            <Link
              to={{
                pathname: "/user",
                search: user.userData && user.userData._id,
              }}
            >
              My Profile
            </Link>
          </MenuItem>
        )}
        {user.isLogin && (
          <MenuItem>
            <Link
              to={{
                pathname: "/user",
                search: user.userData && user.userData._id,
              }}
            >
              My Nft
            </Link>
          </MenuItem>
        )}
        {/* <MenuItem>
          <Link to='/resell-nft'>Resell Nft</Link>
        </MenuItem> */}
        <MenuItem>
          <Link to="/create">Create Collectible</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/item">Search</Link>
        </MenuItem>
        <MenuItem
          component={Button}
          onClick={() => {
            user.logoutHandler();
          }}
        >
          Logout
        </MenuItem>
        {/* <MenuItem
          component={Button}
          onClick={() => {
            user.logoutHandler();
          }}
        >
          Logout
        </MenuItem> */}
      </Menu>

      <Dialog
        open={isWrongNetwork}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={paper}
      >
        <DialogActions>
          <IconButton onClick={handleClose} className={customizedButton}>
            <GiCancel />
          </IconButton>
        </DialogActions>
        <DialogContent className={dialogBox}>
          <Box className="modal_text">
            <Typography variant="body2">Network Error</Typography>

            <Typography variant="h5">
              Please swich to RINKEBY TESTNET...
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
