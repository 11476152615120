import React from "react";
import { Typography, Box, makeStyles, Grid } from "@material-ui/core";
import MyActivityCard, {
  BidCard,
  FolloWUnfollowCard,
  LikeDislikeCard,
} from "src/component/MyActivityCard";
import { Link } from "react-router-dom";

export default function Users(props) {
  const { type, data, dataToDisplay } = props;
  return (
    <Box>
      <Grid container>
        {dataToDisplay.map((data, i) => {
          return (
            <Grid item xs={12} md={12} key={i} className="walletSet mb-20">
              {(data.type === "PLACENFT" ||
                data.type === "SALEORDER" ||
                data.type === "NEWNFT" ||
                data.type === "SALENFT") && (
                <MyActivityCard data={data} type="timing" index={i} />
              )}
              {data.type === "BIDPLACE" && (
                <BidCard data={data} type="timing" index={i} />
              )}
              {(data.type === "FOLLOW" || data.type === "UNFOLLOW") && (
                <FolloWUnfollowCard data={data} type="timing" index={i} />
              )}
              {(data.type === "LIKE" || data.type === "DISLIKE") && (
                <LikeDislikeCard data={data} type="timing" index={i} />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
