import React from "react";
import { Typography, Box, makeStyles, Grid } from "@material-ui/core";
import NewCollection from "src/component/NewCollection";
import { Link } from "react-router-dom";
const walletdetails = [
  {
    img: "images/collections/1.png",
    name: "Digital renderings",
    createdBy: "John",
  },
  {
    img: "images/collections/2.png",
    name: "Gold Record plaque",
    createdBy: "John",
  },
  {
    img: "images/collections/3.png",
    name: "Landmark 1980",
    createdBy: "John",
  },
  {
    img: "images/collections/4.png",
    name: "Digital renderings",
    createdBy: "John",
  },
  {
    img: "images/collections/5.png",
    name: "Gold Record plaque",
    createdBy: "John",
  },
  {
    img: "images/collections/6.png",
    name: "Landmark 1980",
    createdBy: "John",
  },
  {
    img: "images/collections/1.png",
    name: "Digital renderings",
    createdBy: "John",
  },
  {
    img: "images/collections/2.png",
    name: "Gold Record plaque",
    createdBy: "John",
  },
  {
    img: "images/collections/3.png",
    name: "Landmark 1980",
    createdBy: "John",
  },
];
export default function Collection(props) {
  const { type, searchCollectionList } = props;

  return (
    <Box>
      <Grid container>
        {searchCollectionList.map((data, i) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={i}
              className="walletSet mb-20"
            >
              <NewCollection data={data} type="timing" index={i} />
            </Grid>
          );
        })}
        {searchCollectionList && searchCollectionList.length === 0 && (
          <h4>
            No items foundCome back soon! Or try to browse something for you on
            our marketplace!!
          </h4>
        )}
      </Grid>
    </Box>
  );
}
