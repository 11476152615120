import { Contract } from "@ethersproject/contracts";

export function sortAddress(add) {
  const sortAdd = `${add && add.slice(0, 6)}...${
    add && add.slice(add.length - 4)
  }`;
  return sortAdd;
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}
