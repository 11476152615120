import React from 'react';
import { Box, Grid } from '@material-ui/core';
import NFTLikeCard from 'src/component/NFTLikeCard';

export default function Users(props) {
  const { type, data, likesList, userID, callBackFun } = props;

  return (
    <Box>
      <Grid container>
        {likesList.map((data, i) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={i}
              className='walletSet mb-20'
            >
              <NFTLikeCard
                data={data}
                type='timing'
                index={i}
                callBackFun={() => callBackFun(userID)}
              />
              {/* <NFTCard data={data} type="timing" index={i} /> */}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
