import React from "react";
import {
  Typography,
  Box,
  makeStyles,
  Avatar,
  Grid,
  Button,
  Link,
} from "@material-ui/core";
import { FaEllipsisV } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { BsClockHistory } from "react-icons/bs";
import DeleteIcon from "@material-ui/icons/Delete";
import "../scss/main.css";

const useStyles = makeStyles((theme) => ({
  FilterBox: {
    margin: "30px 0",
    background: "#FFFFFF",
    border: "2px solid #F0EDEE",
    borderRadius: " 15px",
    padding: "25px",
    "& h4": {
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "27px",
      marginBottom: "15px",
      color: "#0D8CCD",
    },
    "& button": {},
  },
  FilterDiv: {
    display: "block",

    "& button": {
      // width: "30%",
      margin: "0 5px",
      marginBottom: "15px",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "0px",
      boxShadow: "none",
      borderBottom: "1px solid #E6E6E6",
      borderRadius: "0",
      height: "50px",
      background: "transparent",
      color: "#222",
      "@media (max-width: 767px)": {
        width: "46%",
      },
      "& img": {
        marginRight: "10px",
      },
      "&:hover": {
        backgroundColor: "#E6E6E6",
        boxShadow: "none",
        borderRadius: "5px",
      },
      "&:active": {
        backgroundColor: "#E6E6E6",
        boxShadow: "none",
        borderRadius: "5px",
      },
    },
  },
}));

export default function UsersCard(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { type, data, selectedFilter, setSelectedFilter } = props;
  const classes = useStyles();

  return (
    <Box className={classes.FilterBox}>
      <Typography variant="h4">Filters</Typography>
      <Box className="TabButtonsBox">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Button
              fullWidth
              onClick={() => setSelectedFilter("All")}
              variant="contained"
              // className={classes.button}
              className={selectedFilter === "All" ? "active" : " "}
            >
              <img src="images/icon/Listing.png" alt="" />
              All
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Button
              fullWidth
              onClick={() => setSelectedFilter("Listing")}
              variant="contained"
              className={selectedFilter === "Listing" ? "active" : " "}
            >
              <img src="images/icon/Listing.png" alt="" />
              Listing
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Button
              fullWidth
              onClick={() => setSelectedFilter("Purchases")}
              variant="contained"
              className={selectedFilter === "Purchases" ? "active" : " "}
            >
              <img src="images/icon/Purchases.png" alt="" />
              Purchases
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Button
              fullWidth
              onClick={() => setSelectedFilter("Sales")}
              variant="contained"
              className={selectedFilter === "Sales" ? "active" : " "}
            >
              <img src="images/icon/Sales.png" alt="" />
              Sales
            </Button>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={6}>
            <Button variant='contained' className={classes.button}>
              <img src='images/icon/Transfers.png' alt='' />
              Transfers
            </Button>
          </Grid> */}
          {/* <Grid item xs={12} md={6} lg={6}>
            <Button variant='contained' className={classes.button}>
              <img src='images/icon/Burns.png' alt='' />
              Burns
            </Button>
          </Grid> */}
          <Grid item xs={12} md={6} lg={6}>
            <Button
              fullWidth
              onClick={() => setSelectedFilter("Bids")}
              variant="contained"
              className={selectedFilter === "Bids" ? "active" : " "}
            >
              <img src="images/icon/Bids.png" alt="" />
              Bids
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Button
              fullWidth
              onClick={() => setSelectedFilter("Likes")}
              variant="contained"
              className={selectedFilter === "Likes" ? "active" : " "}
            >
              <img src="images/icon/Likes.png" alt="" />
              Likes
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Button
              fullWidth
              onClick={() => setSelectedFilter("Followings")}
              variant="contained"
              className={selectedFilter === "Followings" ? "active" : " "}
            >
              <img src="images/icon/Followings.png" alt="" />
              Followings
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Button
              fullWidth
              onClick={() => setSelectedFilter("All")}
              variant="contained"
            >
              <img
                src="images/reset.png"
                alt=""
                style={{ width: "30px", height: "32px" }}
              />
              Reset
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
