import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import Page from "src/component/Page";
import { mergeClasses } from "@material-ui/styles";
import Items from "./Items";
import Users from "./Users";
import Collection from "./Collection";
import { useLocation } from "react-router-dom";
import { UserContext } from "src/context/User";

const useStyles = makeStyles((theme) => ({
  Padding_Top: {
    paddingTop: "120px",
    background: "white",
  },
  PageHeading: {
    fontWeight: "600",
    fontSize: "26px",
    lineHeight: "39px",
    color: "#898989",
    paddingBottom: "50px",
    "& span": {
      color: "#000",
    },
  },
}));
function Search(props) {
  const classes = useStyles();
  const [tabview, setTabView] = useState("item");
  const location = useLocation();
  const user = useContext(UserContext);
  const [searchText, setSearchText] = useState("");
  const [searchUserList, setSearchUserList] = useState([]);
  const [searchOrderList, setSearchOrderList] = useState([]);
  const [searchCollectionList, setSearchCollectionList] = useState([]);
  useEffect(() => {
    if (location.search && location.search.slice(1, location.search.length)) {
      let text = location.search.slice(1, location.search.length);
      setSearchText(text);
    } else {
      setSearchText("");
    }
  }, [location]);

  useEffect(() => {
    const userList = user.allUserList.filter(
      (data) =>
        data.name &&
        data.name.toString().toLowerCase().indexOf(searchText.toLowerCase()) !==
          -1
    );
    const orderList = user.allOrders.filter(
      (data) =>
        data.nftId.tokenName &&
        data.nftId.tokenName
          .toString()
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1
    );
    const collectionList = user.allCollection.filter(
      (data) =>
        data.name &&
        data.name.toString().toLowerCase().indexOf(searchText.toLowerCase()) !==
          -1
    );
    setSearchUserList(userList);
    setSearchOrderList(orderList);
    setSearchCollectionList(user.allCollection);
    if (searchText && searchText.length > 1) {
      user.setSearchItemDetails({
        userList: userList,
        orderList: orderList,
        collectionList: collectionList,
      });
    } else {
      user.setSearchItemDetails();
    }
  }, [searchText, user.allUserList, user.allCollection, user.allOrders]);

  useEffect(() => {
    return () => {
      user.setSearchItemDetails();
    };
  }, []);

  return (
    <Page title="Marketplace for NFTs">
      <Box className={classes.Padding_Top}>
        <Container maxWidth="lg">
          <Typography variant="h2" className={classes.PageHeading}>
            Search results for <span>{searchText}</span>
          </Typography>
          <Box className="TabButtonsBox">
            <Button
              className={tabview === "item" ? "active" : " "}
              onClick={() => setTabView("item")}
            >
              Items<span>{searchOrderList.length}</span>
            </Button>
            <Button
              className={tabview === "users" ? "active" : " "}
              onClick={() => setTabView("users")}
            >
              Users<span>{searchUserList.length}</span>
            </Button>
            <Button
              className={tabview === "collection" ? "active" : " "}
              onClick={() => setTabView("collection")}
            > 
              Collections<span>{searchCollectionList.length}</span>
            </Button>
          </Box>
          <Box className="TabButtonsContant">
            {tabview === "item" ? <Items orderList={searchOrderList} /> : ""}
            {tabview === "users" ? (
              <Users searchUserList={searchUserList} />
            ) : (
              ""
            )}
            {tabview === "collection" ? (
              <Collection searchCollectionList={searchCollectionList} />
            ) : (
              ""
            )}
          </Box>
        </Container>
      </Box>
    </Page>
  );
}

export default Search;
