import React from "react";
import { Typography, Box, Button, makeStyles, Grid } from "@material-ui/core";
import MyActivityCard from "src/component/MyActivityCard";
import Filter from "src/component/Filter";
import ActivityList from "src/views/pages/Activity/All";
import { Link } from "react-router-dom";
const walletdetails = [
  {
    profileImg: "images/Activity/1.png",
    name: "GRAFFITI 'thanks for the wall'",
    time: "6/29/2021, 7:53 PM",
    filterIcon: "images/Activity/filter-icon/1.png",
  },
  {
    profileImg: "images/Activity/2.png",
    name: "Atlanta Hawks coin",
    time: "6/29/2021, 7:53 PM",
    filterIcon: "images/Activity/filter-icon/2.png",
  },
  {
    profileImg: "images/Activity/3.png",
    name: "who am i? #11",
    time: "6/29/2021, 7:53 PM",
    filterIcon: "images/Activity/filter-icon/3.png",
  },
  {
    profileImg: "images/Activity/4.png",
    name: "CryptoBusters Car",
    time: "6/29/2021, 7:53 PM",
    filterIcon: "images/Activity/filter-icon/1.png",
  },
  {
    profileImg: "images/Activity/1.png",
    name: "LIL YOU #0021",
    time: "6/29/2021, 7:53 PM",
    filterIcon: "images/Activity/filter-icon/2.png",
  },
  {
    profileImg: "images/Activity/2.png",
    name: "RickyODonnell79",
    time: "6/29/2021, 7:53 PM",
    filterIcon: "images/Activity/filter-icon/3.png",
  },
];
export default function Users(props) {
  const {
    type,
    data,
    activityList,
    userID,
    callBackFun,
    selectedFilter,
    setSelectedFilter,
    allListPageNumber,
    maxPages,
  } = props;

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={12} lg={7}>
          <Grid container>
            <ActivityList dataToDisplay={activityList} />
            {/* {walletdetails.map((data, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  key={i}
                  className='walletSet mb-20'
                >
                  
                  <MyActivityCard data={data} index={i} />
                </Grid>
              );
            })} */}
            {activityList && activityList.length === 0 && (
              <h4>
                Nothing yet Looks like there's still nothing. Activity will be
                shown here
              </h4>
            )}
            {activityList !== 0 && allListPageNumber < maxPages && (
              <Box textAlign="center" mb={2}>
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={async () => {
                    callBackFun(userID, true);
                  }}
                >
                  LOAD MORE
                  {/* {isUpdating && <ButtonCircularProgress />} */}
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={1}></Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Filter
            selectedFilter={selectedFilter}
            setSelectedFilter={(data) => setSelectedFilter(data)}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
