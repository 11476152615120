import React from "react";
import {
  Typography,
  Box,
  makeStyles,
  Avatar,
  Grid,
  Button,
  Link,
} from "@material-ui/core";
import { FaEllipsisV } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { BsClockHistory } from "react-icons/bs";
import { FiExternalLink } from "react-icons/fi";
import moment from "moment";
import { sortAddress } from "src/utils";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  NftImg: {
    borderRadius: 10,
    display: "block",
    miHeight: "300px",
    position: "relative",
  },
  bottomblock: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  bottomTop: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 0",
  },
  playbutton: {
    position: "absolute",
    bottom: 5,
    right: 10,
  },
  activitycard: {
    cursor: "pointer",
    padding: 10,
    background: " #FFFFFF",
    border: "2px solid #F0EDEE",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 767px)": {
      display: "block",
      borderRadius: "27px",
    },
    "& div": {
      paddingLeft: "60px",
      "@media (max-width: 767px)": {
        padding: "15px",
      },
      "& h6": {
        color: "#000",
      },
      "& svg": {
        color: "#000",
        fontSize: "20px",
        cursor: "pointer",
      },
    },
  },
  userComment: {
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "22px",
    color: "#898989",
    marginBottom: "7px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& a": {
      color: "#0D8CCD",
      fontWeight: "bold",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      marginLeft: "10px",
      marginRight: "10px",
    },
  },
  activityimg: {
    position: "relative",
    width: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0",
    "& img": {
      height: "auto",
      maxHeight: "100%",
      maxWidth: "100%",
      display: "block",
    },
    "@media (max-width: 767px)": {
      width: "100%",
    },
    "& img": {
      "&:first-child": {
        height: "auto",
        maxHeight: "100%",
        maxWidth: "100%",
        display: "block",
        "@media (max-width: 767px)": {
          height: "auto",
          maxHeight: "100%",
          maxWidth: "100%",
          display: "block",
        },
      },
      "&:last-child": {
        position: "absolute",
        top: "15px",
        right: "-15px",
        "@media (max-width: 767px)": {
          top: "0",
          right: "0",
        },
      },
    },
  },
  userimg: {
    position: "relative",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    margin: "0",
    marginRight: "10px",
    "& img": {
      "&:first-child": {
        width: "30px",
        height: "30px",
        display: "block",
        objectFit: "cover",
      },
      "&:last-child": {
        position: "absolute",
        top: "0",
        right: "-5px",
      },
    },
  },
}));

export default function NewNFTCard(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { type, data } = props;
  const history = useHistory();
  const classes = useStyles();
  return (
    <Box className="CardBox">
      <Box
        className={classes.activitycard}
        onClick={() => {
          if (data?.orderId?._id) {
            history.push({
              pathname: "/nft-details",
              search: data?.orderId?._id,
            });
          }
        }}
      >
        <figure class={classes.activityimg}>
          <img className="rounded-circle" src={data.nftId.uploadCover} alt="" />
          {data.type === "SALEORDER" ? (
            <img
              src={"images/icon/Purchases.png"}
              style={{
                padding: 7,
                backgroundColor: "#87ae87",
                borderRadius: "50%",
              }}
            />
          ) : (
            <img src={"images/Activity/filter-icon/1.png"} />
          )}
        </figure>
        <Box>
          <Typography variant="h6"> {data.nftId.tokenName}</Typography>
          <Typography veriant="h6" className={classes.userComment}>
            1 edition{" "}
            {data.type === "SALEORDER"
              ? "Purchased"
              : data.type === "SALENFT"
              ? "Bought"
              : data.type === "NEWNFT"
              ? "Added NFT"
              : "Listed"}{" "}
            by {` `}
            {/* <Link to='/'> */}{" "}
            <figure class={classes.userimg}>
              <img
                className="rounded-circle"
                style={{ borderRadius: "50%" }}
                src={
                  data.userId.profilePic
                    ? data.userId.profilePic
                    : "images/users/1.png"
                }
                alt=""
              />
              {data.userId.accountVerify && (
                <img src="images/token/check.png" />
              )}
            </figure>{" "}
            {data.userId.name
              ? data.userId.name
              : sortAddress(data.userId.walletAddress)}{" "}
            {data.nftId.price &&
              `for ${parseFloat(data.nftId.price).toFixed(4)} ETH`}
            {/* </Link>{' '} */}
            {/* to{' '}
            <Link to='/'>
              {' '}
              <figure class={classes.userimg}>
                <img className='rounded-circle' src='images/users/1.png' alt='' />
                <img src='images/token/check.png' />
              </figure>{' '}
              Bunny Parton
            </Link> */}
          </Typography>

          <Typography variant="body2" align="left" color="textSecondary">
            {data.desctiption}
          </Typography>

          <Typography variant="body2" component="span">
            {moment(data.updatedAt).format("DD-MM-YYYY hh:mm A")}{" "}
            {/* <FiExternalLink /> */}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export function FolloWUnfollowCard(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { type, data } = props;
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className="CardBox">
      <Box
        className={classes.activitycard}
        onClick={() => {
          if (data?.orderId?._id) {
            history.push({
              pathname: "/nft-details",
              search: data?.orderId?._id,
            });
          }
        }}
      >
        <figure class={classes.activityimg}>
          <img
            className="rounded-circle"
            src={data.followingUserId.profilePic}
            alt=""
          />
          <img src={"images/Activity/filter-icon/3.png"} />
        </figure>
        <Box>
          <Typography variant="h6"> {data.followingUserId.name}</Typography>
          <Typography veriant="h6" className={classes.userComment}>
            {data.type === "FOLLOW" ? "Follow" : "Unfollow"} {` `}
            {/* <Link to='/'> */}{" "}
            <figure class={classes.userimg}>
              <img
                style={{ borderRadius: "50%" }}
                className="rounded-circle"
                src={
                  data.userId.profilePic
                    ? data.userId.profilePic
                    : "images/users/1.png"
                }
                alt=""
              />
              {data.userId.accountVerify && (
                <img src="images/token/check.png" />
              )}
            </figure>{" "}
            {data.userId.name
              ? data.userId.name
              : sortAddress(data.userId.walletAddress)}
            {/* </Link>{' '} */}
            {/* to{' '}
            <Link to='/'>
              {' '}
              <figure class={classes.userimg}>
                <img className='rounded-circle' src='images/users/1.png' alt='' />
                <img src='images/token/check.png' />
              </figure>{' '}
              Bunny Parton
            </Link> */}
          </Typography>
          <Typography variant="body2" component="span">
            {moment(data.updatedAt).format("DD-MM-YYYY hh:mm A")}{" "}
            {/* <FiExternalLink /> */}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export function LikeDislikeCard(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { type, data } = props;
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className="CardBox">
      <Box
        className={classes.activitycard}
        onClick={() => {
          if (data?.orderId?._id) {
            history.push({
              pathname: "/nft-details",
              search: data?.orderId?._id,
            });
          }
        }}
      >
        <figure class={classes.activityimg}>
          <img className="rounded-circle" src={data.nftId.uploadCover} alt="" />
          <img src={"images/Activity/filter-icon/2.png"} />
        </figure>
        <Box>
          <Typography variant="h6"> {data.nftId.tokenName}</Typography>
          <Typography veriant="h6" className={classes.userComment}>
            {data.type === "LIKE" ? "Liked" : "UnLiked"} by {` `}
            {/* <Link to='/'> */}{" "}
            <figure class={classes.userimg}>
              <img
                className="rounded-circle"
                style={{ borderRadius: "50%" }}
                src={
                  data.userId.profilePic
                    ? data.userId.profilePic
                    : "images/users/1.png"
                }
                alt=""
              />
              {data.userId.accountVerify && (
                <img src="images/token/check.png" />
              )}
            </figure>{" "}
            You
            {data.nftId.price &&
              `(price : ${parseFloat(data.nftId.price).toFixed(4)})`}
            {/* </Link>{' '} */}
            {/* to{' '}
            <Link to='/'>
              {' '}
              <figure class={classes.userimg}>
                <img className='rounded-circle' src='images/users/1.png' alt='' />
                <img src='images/token/check.png' />
              </figure>{' '}
              Bunny Parton
            </Link> */}
          </Typography>
          <Typography variant="body2" component="span">
            {moment(data.updatedAt).format("DD-MM-YYYY hh:mm A")}{" "}
            {/* <FiExternalLink /> */}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export function BidCard(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { type, data } = props;
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className="CardBox">
      <Box
        className={classes.activitycard}
        onClick={() => {
          if (data?.orderId?._id) {
            history.push({
              pathname: "/nft-details",
              search: data?.orderId?._id,
            });
          }
        }}
      >
        <figure class={classes.activityimg}>
          <img className="rounded-circle" src={data.nftId.uploadCover} alt="" />
          <img
            src={"images/icon/Bids.png"}
            style={{
              padding: 7,
              backgroundColor: "yellow",
              borderRadius: "50%",
            }}
          />
        </figure>
        <Box>
          <Typography variant="h6"> {data.nftId.tokenName}</Typography>
          <Typography veriant="h6" className={classes.userComment}>
            1 edition bided by {` `}
            {/* <Link to='/'> */}{" "}
            <figure class={classes.userimg}>
              <img
                className="rounded-circle"
                style={{ borderRadius: "50%" }}
                src={
                  data.bidId.bidderId.profilePic
                    ? data.bidId.bidderId.profilePic
                    : "images/users/1.png"
                }
                alt=""
              />
              {data.bidId.bidderId.accountVerify && (
                <img src="images/token/check.png" />
              )}
            </figure>{" "}
            {data.bidId.bidderId.name
              ? data.bidId.bidderId.name
              : sortAddress(data.bidId.bidderId.walletAddress)}{" "}
            {data.bidId.price &&
              `(price : ${parseFloat(data.bidId.price).toFixed(4)})`}
            {/* </Link>{' '} */}
            {/* to{' '}
            <Link to='/'>
              {' '}
              <figure class={classes.userimg}>
                <img className='rounded-circle' src='images/users/1.png' alt='' />
                <img src='images/token/check.png' />
              </figure>{' '}
              Bunny Parton
            </Link> */}
          </Typography>
          <Typography variant="body2" component="span">
            {moment(data.updatedAt).format("DD-MM-YYYY hh:mm A")}{" "}
            {/* <FiExternalLink /> */}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
