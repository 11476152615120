import React, { useContext, useState, useEffect } from "react";
import { Typography, Box, makeStyles, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FaEllipsisV } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import apiConfig from "src/config/ApiConfig";
import { UserContext } from "src/context/User";
import { getDateDiff, calculateTimeLeft } from "src/services";
import Report, { ShareBox } from "./Report";
import { toast } from "react-toastify";
import moment from "moment";
import { websiteURL } from "src/constants";
import { useWeb3React } from "@web3-react/core";
import { sortAddress } from "src/utils";
const useStyles = makeStyles((theme) => ({
  NftImg: {
    borderRadius: 10,
    display: "block",
    // miHeight: "300px",
    position: "relative",
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    height: "200px",
    overflow: "hidden",
  },
  bottomblock: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  bottomTop: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 0",
  },
  playbutton: {
    position: "absolute",
    bottom: 5,
    right: 10,
  },
  heartHover: {
    "&:hover": {
      // transform: "scale(0.01)",
      borderColor: "red !important",
    },
  },
}));

export default function NFTCard(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openReport, setOpenReport] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const user = useContext(UserContext);
  const token = sessionStorage.getItem("token");

  const [openTimeLeft, setOpenTimeLeft] = useState("");
  const { account } = useWeb3React();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { type, data, callBackFun } = props;
  const classes = useStyles();
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenTimeLeft(
        calculateTimeLeft(new Date(parseInt(data.endTime) * 1000))
      );
    }, 1000);
    return () => clearTimeout(timer);
  });
  const likeDisLikeNftHandler = async (nftId) => {
    if (user.isLogin) {
      try {
        const res = await axios.post(
          apiConfig.likeDisLikeNft,
          {
            nftId,
          },
          {
            headers: {
              token,
            },
          }
        );
        if (res.data.statusCode === 200) {
          toast.success(res.data.responseMessage);
        } else {
          toast.error(res.data.responseMessage);
        }
        user.updateData();
        if (callBackFun) {
          callBackFun();
        }
      } catch (error) {
        toast.error(error.message);
        console.log("ERROR", error);
      }
    } else {
      toast.warning("Please Login");
    }
  };
  if (user.userData) {
    var check_orders = data.nftId.like.filter(
      (order) => order.likerId === user.userData._id
    );
  }
  const timeDiffObj = getDateDiff(new Date(parseInt(data.endTime) * 1000));

  return (
    <Box className="CardBox">
      {openReport && (
        <Report
          opne={openReport}
          setclose={() => setOpenReport(false)}
          orderId={data._id}
          artist={data.userId.name}
        />
      )}
      {openShare && (
        <ShareBox
          url={websiteURL + "nft-details?" + data._id}
          open={openShare}
          setClose={() => setOpenShare(false)}
          props={props}
        />
      )}

      <Box className="collectionSet" style={{ padding: "20px" }}>
        <Box className="card_heading">
          <Box className="card_text">
            <Box className="token_box">
              <Box className="Token_img">
                <img
                  src={
                    data.collectionId && data.collectionId.collectionImage
                      ? data.collectionId.collectionImage
                      : "images/token/2.png"
                  }
                  alt=""
                />
              </Box>
              <Box className="Token_img">
                <img
                  src={
                    data.userId.profilePic
                      ? data.userId.profilePic
                      : "images/token/1.png"
                  }
                  alt=""
                />
                {data.userId.accountVerify && (
                  <img
                    src={"images/token/check.png"}
                    className="check_icon"
                    alt=""
                  />
                )}
              </Box>
            </Box>
            <Typography variant="h6">
              {data.nftId.tokenName && data.nftId.tokenName.length >= 12
                ? sortAddress(data?.nftId?.tokenName)
                : data.nftId.tokenName}
            </Typography>
          </Box>

          <Typography variant="body1" onClick={handleClick}>
            <FaEllipsisV />
          </Typography>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenShare(true);
              }}
            >
              Share
            </MenuItem>
            {user?.userData?._id !== data?.nftId?.userId && (
              <MenuItem
                onClick={() => {
                  if (account) {
                    handleClose();
                    setOpenReport(true);
                  } else {
                    toast.warn("Please connect to your wallet");
                  }
                }}
              >
                Report
              </MenuItem>
            )}
          </Menu>
        </Box>
        <Box className={classes.NftImg}>
          <Link
            to={{
              pathname: "/nft-details",
              search: data._id,
            }}
          >
            <img
              src={
                data.nftId.uploadCover
                  ? data.nftId.uploadCover
                  : "images/card/1.png"
              }
              width="100%"
              alt=""
              style={{
                borderRadius: "5px",
                maxHeight: "100%",
                maxWidth: "100%",
                width: "auto",
                height: "auto",
              }}
            />
          </Link>
          {/* <Box className={classes.playbutton} >
            <Avatar style={{backgroundColor:"#1ed760",cursor:"pointer"}}>
              <PlayArrowIcon/>
            </Avatar>
              </Box> */}
        </Box>
        {type == "timing" ? (
          ""
        ) : (
          <>
            {parseFloat(data.endTime) < moment().unix() ? (
              <Box className="timing">
                <Typography variant="body2" component="label">
                  Expired
                </Typography>
              </Box>
            ) : (
              <Box className="timing">
                {openTimeLeft.days && openTimeLeft.days != 0 ? (
                  <Typography variant="body2" component="label">
                    {openTimeLeft.days && openTimeLeft.days
                      ? openTimeLeft.days
                      : "0"}
                    d(s)
                  </Typography>
                ) : (
                  ""
                )}
                <Typography variant="body2" component="label">
                  {openTimeLeft.hours && openTimeLeft.hours
                    ? openTimeLeft.hours
                    : "0"}
                  h
                </Typography>
                <Typography variant="body2" component="label">
                  {openTimeLeft.minutes && openTimeLeft.minutes
                    ? openTimeLeft.minutes
                    : "00"}
                  m
                </Typography>
                <Typography variant="body2" component="label">
                  {openTimeLeft.seconds && openTimeLeft.seconds
                    ? openTimeLeft.seconds
                    : "00"}
                  s
                </Typography>
                <Typography variant="body2" component="label">
                  left
                </Typography>
                <img src="images/icon.png" alt="" />
              </Box>
            )}
          </>
        )}

        <Box mt={2}>
          <Grid container justify="space-between">
            <Grid item className="NFTDetailsBox"></Grid>
            <Box className="FooterData">
              <Box>
                {/* <Typography variant='h6'>Highest bid 1/1</Typography> */}
                {data.orderType === "TIMED_AUCTION" ||
                data.orderType === "AUCTION_FIXEDPRICE_BOTH" ? (
                  <Typography variant="body2">
                    {data.startPrice ? data.startPrice : "-"}
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    {data.price ? data.price : "-"}
                  </Typography>
                )}
              </Box>
              <Box className="d-flex justifyEnd text-black" pt={0}>
                <Typography className="like_box">
                  {" "}
                  <FaHeart
                    onClick={() => likeDisLikeNftHandler(data.nftId._id)}
                    style={
                      check_orders && check_orders.length > 0
                        ? { fontSize: "16px", color: "red" }
                        : { fontSize: "16px", color: "#231F20" }
                    }
                    className={classes.heartHover}
                  />
                </Typography>
                <span
                  style={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#3D3D3D",
                  }}
                >
                  {data.nftId.likesCount ? data.nftId.likesCount : 0}
                </span>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
