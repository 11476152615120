import React, { useContext } from "react";
import { Typography, Box, makeStyles, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FaEllipsisV } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import apiConfig from "src/config/ApiConfig";
import { UserContext } from "src/context/User";
import { getDateDiff } from "src/services";
import Report, { ShareBox } from "./Report";
import { toast } from "react-toastify";
import { websiteURL } from "src/constants";

const useStyles = makeStyles((theme) => ({
  NftImg: {
    borderRadius: 10,
    display: "block",
    miHeight: "300px",
    position: "relative",
  },
  bottomblock: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  bottomTop: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 0",
  },
  playbutton: {
    position: "absolute",
    bottom: 5,
    right: 10,
  },
}));

export default function NFTLikeCard(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openReport, setOpenReport] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const user = useContext(UserContext);
  const token = sessionStorage.getItem("token");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { type, data, callBackFun } = props;
  const classes = useStyles();
  const likeDisLikeNftHandler = async (nftId) => {
    if (user.isLogin) {
      try {
        const res = await axios.post(
          apiConfig.likeDisLikeNft,
          {
            nftId,
          },
          {
            headers: {
              token,
            },
          }
        );
        if (res.data.statusCode === 200) {
          toast.success(res.data.responseMessage);
        } else {
          toast.error(res.data.responseMessage);
        }
        user.updateData();
        if (callBackFun) {
          callBackFun();
        }
      } catch (error) {
        toast.error(error.message);

        console.log("ERROR", error);
      }
    } else {
      toast.warning("Please Login");
    }
  };
  if (user.userData) {
    var check_orders = data.like.filter(
      (order) => order.likerId === user.userData._id
    );
  }

  const timeDiffObj = getDateDiff(new Date(parseInt(data.endTime) * 1000));

  return (
    <Box className="CardBox">
      {openReport && (
        <Report
          opne={openReport}
          setclose={() => setOpenReport(false)}
          orderId={data._id}
          artist={data.userId.name}
        />
      )}
      {openShare && (
        <ShareBox
          open={openShare}
          url={websiteURL + "nft-details?" + data.orderId._id}
          setClose={() => setOpenShare(false)}
        />
      )}
      <Box className="collectionSet" style={{ padding: "20px" }}>
        <Box className="card_heading">
          <Box className="card_text">
            <Box className="token_box">
              <Box className="Token_img">
                <img
                  src={
                    data.userId.profilePic
                      ? data.userId.profilePic
                      : "images/token/1.png"
                  }
                  alt=""
                />
              </Box>
              <Box className="Token_img">
                <img
                  src={
                    data.collectionId && data.collectionId.collectionImage
                      ? data.collectionId.collectionImage
                      : "images/token/2.png"
                  }
                  alt=""
                />
                <img
                  src={"images/token/check.png"}
                  className="check_icon"
                  alt=""
                />
              </Box>
            </Box>
            <Typography variant="h6">{data.tokenName}</Typography>
          </Box>

          <Typography variant="body1" onClick={handleClick}>
            <FaEllipsisV />
          </Typography>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenShare(true);
              }}
            >
              Share
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenReport(true);
              }}
            >
              Report
            </MenuItem>
          </Menu>
        </Box>
        <Box className={classes.NftImg}>
          <Link
            to={{
              pathname: "/nft-details",
              search: data.orderId._id,
            }}
          >
            <img
              src={data.uploadCover ? data.uploadCover : "images/card/1.png"}
              width="100%"
              alt=""
              style={{ borderRadius: "5px", height: 150 }}
            />
          </Link>
          {/* <Box className={classes.playbutton} >
            <Avatar style={{backgroundColor:"#1ed760",cursor:"pointer"}}>
              <PlayArrowIcon/>
            </Avatar>
              </Box> */}
        </Box>
        {type == "timing" ? (
          ""
        ) : (
          <Box className="timing">
            {timeDiffObj.days && timeDiffObj.days != 0 ? (
              <Typography variant="body2" component="label">
                {timeDiffObj.days && timeDiffObj.days}d(s)
              </Typography>
            ) : (
              ""
            )}
            <Typography variant="body2" component="label">
              {timeDiffObj.hours && timeDiffObj.hours}h
            </Typography>
            <Typography variant="body2" component="label">
              {timeDiffObj.minutes && timeDiffObj.minutes}m
            </Typography>
            <Typography variant="body2" component="label">
              {timeDiffObj.seconds && timeDiffObj.seconds}s
            </Typography>
            <Typography variant="body2" component="label">
              left
            </Typography>
            <img src="images/icon.png" alt="" />
          </Box>
        )}

        <Box mt={2}>
          <Grid container justify="space-between">
            <Grid item className="NFTDetailsBox"></Grid>
            <Box className="FooterData">
              <Box>
                {/* <Typography variant='h6'>Highest bid 1/1</Typography> */}

                <Typography variant="body2">
                  {data.orderId.price ? data.orderId.price : "-"}
                </Typography>
              </Box>
              <Box className="d-flex justifyEnd text-black" pt={0}>
                <Typography className="like_box">
                  {" "}
                  <FaHeart
                    onClick={() => likeDisLikeNftHandler(data._id)}
                    style={
                      check_orders && check_orders.length > 0
                        ? { fontSize: "16px", color: "red" }
                        : { fontSize: "16px", color: "#231F20" }
                    }
                  />
                </Typography>
                <span
                  style={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#3D3D3D",
                  }}
                >
                  {data.likesCount ? data.likesCount : 0}
                </span>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
